:root{
    --font-family: "Roboto", sans-serif;
    --font-size-base:14px;
    --menu-background: #f0f0f0;
    --menu-background-hover: #fafafa;
    --menu-background-border: #ddd;
    --selection-background: #337AB7;
    --selection-foreground: #fff;
    --layout-breakpoint: 768px;

    --default-background: #fff;
    --default-foreground: #000;

    --link-color: rgb(33, 92, 159);
    --active-link-color: rgb(32, 136, 217);

    --default-foreground-transparent-40: rgba(0,0,0,.4);
    --default-background-transparent-70: rgba(255,255,255,.7);



    --row-highlight-green: #cbffcb;
    --row-highlight-green-alt: #bbe0bb;
    --row-highlight-red: #ffc2c2;
    --row-highlight-red-alt: #d7b3b3;
    --row-highlight-orange: #ffe97b;
    --row-highlight-orange-alt: #cbb569;
    --row-highlight-blue: #bdbdff;
    --row-highlight-blue-alt: #9f9fcc;
    --row-highlight-yellow: #ffffa5;
    --row-highlight-yellow-alt: #c9c998;
}
