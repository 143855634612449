@import './colors.css';
@import './shadows.css';
@import './transitions.css';

.card {
    box-shadow: var(--shadow-material-default);
    background: var(--default-background);
    padding: 1em;
    border-radius: 3px;
    transition: box-shadow var(--transition-time-default) ease-out;
}

.cardHorizontal{
    composes: card;
    margin: 0 1em 1em 0;
}

.card:hover {
    box-shadow: var(--shadow-material-hover);
}

.noMargins {
    composes: card;
}

.allMargins{
    composes: card;
    margin: 1em;
}

.expandable{
    composes: card;
}

.card + .card {
    margin-top: 1em;
}

.cardHorizontal + .cardHorizontal{
    margin: 0 1em 1em 0;
}

.card + .noMargins {
    margin-top: 0;
}

.header{
    margin:0 0 1em 0;
    padding:0;
    font-size:120%;
    font-weight: normal;
}
.expandable .header{
    margin: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;
}

.expandable .header + *{
    margin-top: 1em;
}

.expandMarker{display: block;}
.expanded{}

.expandMarker.expanded:after{
    transform: rotate(-90deg);
}

.expandMarker:after{
    font-family: FontAwesome;
    transform: rotate(90deg);
    transform-origin: 2px 10px;
    transition: transform ease .3s;
    filter: blur(0);
    display: block;
    content: '\f0da';
}

.divider {
    margin: 1em -1em;
    border-top: 1px solid var(--color-gray-highlight);
    height: 0;
}

.subheader {
    margin: 0 0 .5em 0;
    padding: 0;
    font-size: 110%;
    font-weight: normal;
}
