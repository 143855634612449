.fullscreen{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}
.wrapper{
    composes: fullscreen;

    background: var(--default-background);
    color: var(--default-foreground);
}

a, a:visited{
    color: var(--link-color);
    text-decoration: none;
}
a:hover, a:active{
    color: var(--active-link-color);
    text-decoration: underline;
}
