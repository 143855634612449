@import './colors.css';
@import './transitions.css';

.base {
    border: none;
    border-radius: 2px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    transition: var(--transition-all-default);
    text-decoration: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.base:hover, .base:focus {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
    text-decoration: none;
}

.base:active {
    box-shadow: none;
    text-decoration: none;
}

.base.disabled,
.base[disabled],
fieldset[disabled] .base {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    box-shadow: none;
    opacity: .65;
}

.large {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    letter-spacing: .5px;
}

.medium {
    height: 34px;
    line-height: 36px;
    padding: 0 12px;
    font-size: 14px;
    letter-spacing: .5px;
}

.small {
    height: 28px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    letter-spacing: .33px;
}

.xsmall {
    height: 24px;
    line-height: 26px;
    padding: 0 7px;
    font-size: 10px;
    letter-spacing: .25px;
}

.inline {
    padding: 0px 7px;
    letter-spacing: .25px;
    font-size: 80%;
    line-height: 2em;
    margin-top: -3px;
}


.grayForeground{
    color: var(--color-gray-inverted);
}
.grayBackground{
    background-color: var(--color-gray);
}

.gray {
    composes: base;
    composes: grayForeground;
    composes: grayBackground;
    border-color: var(--color-gray-darker);
}

a.gray, a.gray:visited, a.gray:hover, a.gray:active {
    color: var(--color-gray-inverted);
}

.gray:hover, .gray:active, .gray:focus, a.gray:hover, a.gray:active, a.gray:focus {
    background-color: var(--color-gray-alt);
    border-color: var(--color-gray-darker-alt);
    color: var(--color-gray-inverted-alt);
}

.red {
    composes: base;
    background-color: var(--color-red);
    border-color: var(--color-red-darker);
    color: var(--color-red-inverted);
}

a.red, a.red:active, a.red:hover, a.red:visited {
    color: var(--color-red-inverted);
}

.red:hover, .red:active, .red:focus, a.red:hover, a.red:active, a.red:focus {
    background-color: var(--color-red-alt);
    border-color: var(--color-red-darker-alt);
    color: var(--color-red-inverted-alt);
}

.blue {
    composes: base;
    background-color: var(--color-blue);
    border-color: var(--color-blue-darker);
    color: var(--color-blue-inverted);
}

a.blue, a.blue:active, a.blue:hover, a.blue:visited {
    color: var(--color-blue-inverted);
}

.blue:hover, .blue:active, .blue:focus, a.blue:hover, a.blue:active, a.blue:focus {
    background-color: var(--color-blue-alt);
    border-color: var(--color-blue-darker-alt);
    color: var(--color-blue-inverted-alt);
}


.green {
    composes: base;
    background-color: var(--color-green);
    border-color: var(--color-green-darker);
    color: var(--color-green-inverted);
}

a.green, a.green:active, a.green:hover, a.green:visited {
    color: var(--color-green-inverted);
}

.green:hover, .green:active, .green:focus, a.green:hover, a.green:active, a.green:focus {
    background-color: var(--color-green-alt);
    border-color: var(--color-green-darker-alt);
    color: var(--color-green-inverted-alt);
}


.orange {
    composes: base;
    background-color: var(--color-orange);
    border-color: var(--color-orange-darker);
    color: var(--color-orange-inverted);
}

a.orange, a.orange:active, a.orange:hover, a.orange:visited {
    color: var(--color-orange-inverted);
}

.orange:hover, .orange:active, .orange:focus, a.orange:hover, a.orange:active, a.orange:focus {
    background-color: var(--color-orange-alt);
    border-color: var(--color-orange-darker-alt);
    color: var(--color-orange-inverted-alt);
}

.link{
    cursor: pointer;
    color: var(--link-color);
}
.link:hover{
    color: var(--active-link-color);
}

.splitLeft{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.splitRight{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.splitRightCaret {
    composes:splitRight;
    padding: 0 !important;
}
