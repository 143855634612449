@import './colors.css';
.textDisplay, .textDisplay:focus{
    border:none;
    outline: none;
    display:inline-block;
    padding:0;
    margin:0;
    background:transparent;
    color: var(--material-foreground);
}

.bordered {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: var(--material-foreground);
    background: var(--material-control-background) none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: all ease .2s;
}

.block{
    display:block;
    width:100%;
}

.bordered.focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}
.borderedSmall{
    composes: bordered;
    height: 28px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
}
.borderedXSmall {
    composes: bordered;
    height: 24px;
    line-height: 26px;
    padding: 0 7px;
    font-size: 10px;
}

.materialWrapper {
    display: block;
    position: relative;
    width: 100%;
    color: var(--material-foreground);
}

.materialWrapperInvalid{

}

.materialWrapperActive {
}

.materialWrapperActive .materialInput {
    border-bottom: 2px solid var(--material-accent);
    margin: 0;
}

.materialWrapperNoAutofocus {
}

.materialInputFocus:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid var(--material-accent);
    padding: 15px 0 3px 0;
}

.materialInput {
    composes: materialInputFocus;
    display: block;
    border: none;
    outline: none;
    padding: 15px 0 4px 0;
    border-bottom: 1px solid var(--material-foreground);
    background: var(--material-control-background);
    color: var(--material-foreground);

    width: 100%;
}

.materialInputInvalid{
    border-bottom-color: var(--color-red);
}

.materialTextarea {
    composes: materialInputFocus;
    resize: none;
    box-sizing: border-box;
    display: inline-table;
}

.materialLabel {
    position: absolute;
    top: 13px;
    left: 0;
    transition: all ease .2s;
    cursor: text;
    font-weight: 400;
    z-index:1;
}

.materialWrapperActive .materialLabel {
    top: 0;
    font-size: 10px;
    color: var(--material-accent);
}

.materialWrapperInvalid .materialLabel {
    color: var(--color-red) !important;
}

.materialWrapperNotEmpty{}

.materialWrapperNotEmpty .materialLabel {
    top: 0;
    font-size: 10px;
}

.materialNote {
    font-size: 75%;
}
.materialValidationError{
    font-size:75%;
    color:var(--color-red-darker);
}

.materialRaw{
    border: none;
    border-bottom: 1px solid var(--material-foreground);
    padding-bottom: 1px;
}
.materialRaw:focus{
    outline: none;
    border-bottom: 2px solid var(--material-accent);
    padding-bottom: 0;
}
