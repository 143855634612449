@import "../../../css-mixins/inputs.css";

.RequiredMark {
    position: absolute;
    right: 0;
    bottom: .6em;
    z-index: 2;
    display: block;
    text-align: center;
    pointer-events: none;
}
