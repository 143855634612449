[data-theme="dark"]{
    --menu-background: #444;
    --menu-background-hover: #555;
    --menu-background-border: #999;

    --material-background: #444;
    --material-foreground: #ccc;
    --material-accent: #386279;
    --material-accent-inverted: #ccc;

    --selection-background: var(--material-accent);
    --selection-foreground: #ccc;

    --material-selection-background: #ccc;
    --material-selection-foreground: var(--material-accent);
    --material-control-background: #444;
    --material-disabled-control-background: #222;

    --material-alternating-background: #454545;
    --material-alternating-background-2: #555555;
    --material-border: #999;

    --link-color: rgb(99, 172, 224);
    --active-link-color: rgb(106, 153, 176);

    --default-background: #222;
    --default-background-transparent-70: rgba(32,32,32,.7);
    --default-foreground: #ccc;
    --default-foreground-transparent-40: rgba(220,220,220,.4);


    --row-highlight-green:#242;
    --row-highlight-green-alt:#353;
    --row-highlight-red:#411;
    --row-highlight-red-alt:#511;
    --row-highlight-orange:#520;
    --row-highlight-orange-alt:#630;
    --row-highlight-blue:#336;
    --row-highlight-blue-alt:#447;
    --row-highlight-yellow:#663;
    --row-highlight-yellow-alt:#774;
}
