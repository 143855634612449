:root {
    --color-gray: #777;
    --color-green: #5cb85c;
    --color-blue: #5bc0de;
    --color-orange: #f0ad4e;
    --color-red: #d9534f;

    --color-gray-lighter: #ddd;
    --color-green-lighter: #cfc;
    --color-blue-lighter: #ccf;
    --color-orange-lighter: #fec;
    --color-red-lighter: #fcc;

    --color-gray-inverted: #fff;
    --color-green-inverted: #fff;
    --color-blue-inverted: #fff;
    --color-orange-inverted: #fff;
    --color-red-inverted: #fff;

    --color-gray-darker: #444;
    --color-green-darker: #4cae4c;
    --color-blue-darker: #2a5e7a;
    --color-orange-darker: #eea236;
    --color-red-darker: #d43f3a;

    --color-gray-alt: #666;
    --color-green-alt: #449d44;
    --color-blue-alt: #31b0d5;
    --color-orange-alt: #ec971f;
    --color-red-alt: #ac2925;

    --color-gray-highlight: #c8c8c8;
    --color-green-highlight: #daffda;
    --color-blue-highlight: #c7ebfd;
    --color-orange-highlight: #FFE3A1;
    --color-red-highlight: #ffd2d2;

    --color-gray-inverted-alt: #fff;
    --color-green-inverted-alt: #fff;
    --color-blue-inverted-alt: #fff;
    --color-orange-inverted-alt: #fff;
    --color-red-inverted-alt: #fff;

    --color-gray-darker-alt: #333;
    --color-green-darker-alt: #398439;
    --color-blue-darker-alt: #269abc;
    --color-orange-darker-alt: #985f0d;
    --color-red-darker-alt: #761c19;

    --material-background: #eee;
    --material-foreground: #555;
    --material-accent: #494;
    --material-accent-inverted: #fff;
    --material-control-background: #fff;
    --material-disabled-control-background: #eee;

    --material-selection-background: #ccc;
    --material-selection-foreground: var(--material-accent);

    --material-alternating-background: #f3f3f3;
    --material-alternating-background-2: #f7f7f7;
    --material-border: #ddd;


    --color-product-publisher: #3a64af;
    --color-product-fbonline: #2769a4;
}
